
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
font-family: 'Montserrat', sans-serif;
 background-color: #b71c1c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.space-header{
  display: flex;
  /* Cette valeur peut être changée dans l'exemple */
  justify-content: space-between;

}


.App-header {
  background-color: #b71c1c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar-brand{
    float:left;

    padding:1px 15px;
    font-size:18px;
    line-height:20px;

}


.field.focussed input + label {
  top: 4px;
  opacity: 1;
  color: #28cc70;
}
.field.locked {
 pointer-events: none;
}
.field input {
  width: 100%;
  height: 49px;
  margin:4px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  background-color: white;
  border: 1px solid #ccc;
  color: #000;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
}
.field input::-webkit-input-placeholder {
  color: grey;
}
.field input::-moz-placeholder {
  color: grey;
}
.field input:-ms-input-placeholder {
    color: grey;
}
.field input:-moz-placeholder {
    color: grey;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
